<template>
  <div style="width: 100%; height: 100%">
    <image-box v-if="$store.state.authed" />
    <user-auth v-else />
  </div>
</template>

<script>
import ImageBox from "@/components/ImageBox";
import UserAuth from "@/components/UserAuth";

export default {
  name: "Index",
  components: {
    ImageBox,
    UserAuth,
  },
  mounted() {},
  watch: {
    $route() {
      const langs = ["zht", "en"];
      if (langs.includes(this.$route.params.lang)) {
        this.$i18n.locale = this.$route.params.lang;
      } else {
        this.$i18n.locale = "zht";
      }
    },
  },
};
</script>

<style>
</style>
