<template>
  <div class="auth-container">
    <div class="lang-change">
      <router-link v-if="$route.params.lang" :to="{ path: '/' }"
        >zht</router-link
      >
      <router-link v-else :to="{ path: '/en' }">en</router-link>
    </div>
    <template v-if="mode === 'login'">
      <p class="auth-title">{{ $t("auth.loginTitle") }}</p>
      <div class="auth-form" @keyup.enter="handleLogin">
        <input
          v-model="username"
          class="auth-form-input"
          type="text"
          placeholder="USERNAME"
          autocomplete="off"
        />
        <input
          v-model="password"
          class="auth-form-input"
          type="password"
          placeholder="PASSWORD"
          autocomplete="off"
        />
        <div class="auth-form-button" @click="handleLogin">
          {{ $t("auth.loginButton") }}
        </div>
        <div class="auth-form-button" @click="changeMode('register')">
          {{ $t("auth.registerButton") }}
        </div>
      </div>
    </template>
    <template v-if="mode === 'register'">
      <p class="auth-title">{{ $t("auth.registerTitle") }}</p>
      <div class="auth-form" @keyup.enter="handleRegister">
        <input
          v-model="regUsername"
          class="auth-form-input"
          type="text"
          placeholder="USERNAME"
          autocomplete="off"
        />
        <input
          v-model="regPassword"
          class="auth-form-input"
          type="password"
          placeholder="PASSWORD"
          autocomplete="off"
        />
        <input
          v-model="regPasswordRepeat"
          class="auth-form-input"
          type="password"
          placeholder="RE-PASSWORD"
          autocomplete="off"
        />
        <div class="captcha-box">
          <van-row>
            <van-col span="16">
              <input
                v-model="regCaptcha"
                class="auth-form-input"
                type="text"
                placeholder="CAPTCHA"
                autocomplete="off"
            /></van-col>
            <van-col span="8" @click="getCaptcha"
              ><img class="captcha-image" :src="regCaptchaImage" alt=""
            /></van-col>
          </van-row>
        </div>
        <div class="auth-form-button" @click="handleRegister">
          {{ $t("auth.registerButton") }}
        </div>
        <div class="auth-form-button" @click="changeMode('login')">
          {{ $t("auth.loginButton") }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Notify } from "vant";
import { setStorage, getStorage } from "@/utils/storage";
import { setThemeColor } from "@/utils/browser";

export default {
  data() {
    return {
      username: null,
      password: null,
      mode: "login",
      regUsername: null,
      regPassword: null,
      regPasswordRepeat: null,
      regCaptcha: null,
      regCaptchaKey: null,
      regCaptchaImage: null,
    };
  },
  mounted() {
    if (getStorage("token")) {
      this.$store.commit("userAuthed");
    }
    setThemeColor("#F5B7B1");
    if (this.mode === "register") {
      this.getCaptcha();
    }
  },
  methods: {
    handleLogin() {
      if (this.username === null || this.password === null) {
        Notify({ type: "warning", message: this.$t("auth.emptyLoginForm") });
        return;
      }
      this.$axios
        .post("/auth/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          setStorage("token", response.data.token);
          Notify({ type: "success", message: this.$t("auth.loginSuccess") });
          this.$store.commit("userAuthed");
        })
        .catch((err) => {
          Notify({ type: "warning", message: err.response.data.message });
          this.password = null;
        });
    },
    getCaptcha() {
      this.$axios.get("/auth/captcha").then((response) => {
        this.regCaptchaKey = response.data.key;
        this.regCaptchaImage = response.data.image;
      });
    },
    handleRegister() {
      if (this.regUsername === null) {
        Notify({ type: "warning", message: this.$t("auth.emptyUsername") });
        return;
      }
      if (this.regPassword === null) {
        Notify({ type: "warning", message: this.$t("auth.emptyPassword") });
        return;
      }
      if (this.regPasswordRepeat === null) {
        Notify({
          type: "warning",
          message: this.$t("auth.emptyPasswordRepeat"),
        });
        return;
      }
      if (this.regPasswordRepeat !== this.regPassword) {
        Notify({
          type: "warning",
          message: this.$t("auth.failPasswordRepeat"),
        });
        return;
      }
      if (this.regCaptcha === null) {
        Notify({ type: "warning", message: this.$t("auth.emptyCaptcha") });
        return;
      }

      this.$axios
        .post("/auth/register", {
          username: this.regUsername,
          password: this.regPassword,
          captcha: this.regCaptcha,
          key: this.regCaptchaKey,
        })
        .then((response) => {
          setStorage("token", response.data.token);
          Notify({ type: "success", message: this.$t("auth.registerSuccess") });
          this.$store.commit("userAuthed");
        })
        .catch((err) => {
          Notify({ type: "warning", message: err.response.data.message });
          this.regCaptcha = null;
          this.getCaptcha();
        });
    },
    changeMode(mode) {
      this.mode = mode;
      if (this.mode === "register") {
        this.getCaptcha();
      }
    },
  },
};
</script>

<style>
.auth-container {
  width: 100%;
  height: 100%;
  background: #f5b7b1;
}

.auth-title {
  text-align: center;
  color: #ffffff;
  font-size: 36px;
  position: relative;
  top: 20%;
}

.auth-form {
  text-align: center;
  position: relative;
  top: 30%;
}

.captcha-box {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}

.captcha-box .auth-form-input {
  width: 100% !important;
}

.captcha-image {
  margin: 10px 0;
  width: 100%;
  height: 40px;
  border-radius: 25px;
}

.auth-form-input,
.auth-form-button {
  box-sizing: border-box;
  width: 80%;
  height: 40px;
  border: 2px solid #ffffff;
  margin: 10px 0;
  padding: 5px 20px;
  border-radius: 25px;
}

.auth-form-button {
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
  color: #ffffff;
}

.lang-change {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
}
</style>