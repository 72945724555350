<template>
  <div class="container" ref="container">
    <div id="icp">
      <canvas id="ic" style="width: 100%; height: 100%"></canvas>
    </div>
    <div
      class="cover"
      id="cover"
      @click="handleClick"
      @keydown="handleKeyDown"
      tabindex="0"
    >
      <div class="loading" v-if="showLoading">
        <van-loading size="60" vertical>loading...</van-loading>
      </div>
    </div>
    <div class="control" v-if="showControl">
      <div class="info">
        <p>
          Index: {{ imageIndex + 1 }}/{{ imageList.length }} | Size:
          {{ (imageItem.image.length / 1000).toFixed(2) }}k | View:
          {{ imageItem.meta.view }} | Rate:{{imageItem.meta.rate_all?imageItem.meta.rate_all:'N/A'}}
        </p>
      </div>
      <div class="tools">
        <van-grid style="height: 60px" :column-num="6" clickable>
          <van-grid-item icon="arrow-left" @click="handlePreviousImage" />
          <van-grid-item @click="handleLikeClick">
            <template #icon>
              <van-icon
                v-if="imageItem.meta.like"
                name="like"
                color="#ee0a24"
                size="28"
              />
              <van-icon v-else name="like-o" size="28" />
            </template>
          </van-grid-item>
          <van-grid-item @click="openRateBox">
            <template #icon>
              <van-icon
                v-if="imageItem.meta.rate"
                name="star"
                color="#FF9900"
                size="28"
              />
              <van-icon v-else name="star-o" size="28" />
            </template>
          </van-grid-item>
          <van-grid-item icon="question-o" />
          <van-grid-item icon="user-circle-o" />
          <van-grid-item icon="arrow" @click="handleNextImage" />
        </van-grid>
      </div>
    </div>
    <div class="lock" ref="lock">
      <div class="lockinfo"><p>Screenshot is denied.</p></div>
    </div>
    <van-action-sheet
      v-model="showRateBox"
      title="評分"
      :closeable="false"
      style="z-index: 99999"
    >
      <div class="rate-box">
        <van-row>
          <template v-if="imageItem && imageItem.meta.rate">
            <van-col span="12" offset="4">
              <van-rate
                v-model="imageItem.meta.rate"
                :size="25"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
              />
            </van-col>
            <van-col span="8">
              <van-button plain size="mini" hairline type="primary" disabled
                >已評分</van-button
              >
              <van-button
                plain
                size="mini"
                hairline
                type="info"
                @click="showRateBox = false"
                >關閉</van-button
              >
            </van-col>
          </template>
          <template v-else>
            <van-col span="12" offset="4">
              <van-rate
                v-model="selectedRate"
                :size="25"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
              />
            </van-col>
            <van-col span="8">
              <van-button
                plain
                size="mini"
                hairline
                type="primary"
                @click="updateRate"
                >確定</van-button
              >
              <van-button
                plain
                size="mini"
                hairline
                type="info"
                @click="showRateBox = false"
                >關閉</van-button
              >
            </van-col>
          </template>
        </van-row>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Slide from "@/utils/slide";
import { Notify } from "vant";
import { getStorage, removeStorage } from "@/utils/storage";
import { setThemeColor } from "@/utils/browser";

export default {
  data() {
    return {
      canvasWidth: 1024 * 2,
      canvasHeight: 768 * 2,
      ic: null,
      ctx: null,
      cover: null,
      imageList: [],
      imageIndex: -1,
      showLoading: false,
      showControl: false,
      imageItem: null,
      showRateBox: false,
      selectedRate: 5,
    };
  },
  mounted() {
    this.ic = document.getElementById("ic");
    this.ctx = this.ic.getContext("2d");
    this.resizeCanvas();
    window.onresize = () => {
      this.resizeCanvas();
    };
    this.cover = document.getElementById("cover");
    Slide.listenTouchDirection(
      this.cover,
      true,
      false,
      this.handlePreviousImage,
      false,
      this.handleNextImage
    );
    setThemeColor("#000000");
    /*
    let lockcount = 0;
    setInterval(() => {
      this.$refs.lock.style.display =
        lockcount % 2 === 0 ? "block" : "none";
      lockcount++;
    }, 1);
    */
  },
  watch: {
    /*
    imageList() {
      if (!this.imageList[this.imageIndex]) {
        this.showLoading = true;
      } else {
        this.showLoading = false;
      }
      this.drawImage(this.imageList[this.imageIndex]);
    },*/
  },
  methods: {
    resizeCanvas() {
      let icp = document.getElementById("icp");
      this.canvasWidth = icp.clientWidth * 2;
      this.canvasHeight = icp.clientHeight * 2;
      this.ic.width = this.canvasWidth;
      this.ic.height = this.canvasHeight;
      this.handleNextImage();
      //this.prepareImageList();
    },
    drawImage(image, x = 0) {
      console.log(this.ic.width, this.ic.height);
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = image;
      img.onload = () => {
        let br = this.canvasWidth / this.canvasHeight;
        let ir = img.width / img.height;
        if (br > ir) {
          this.ctx.drawImage(
            img,
            (this.canvasWidth - this.canvasHeight * ir) / 2 + x,
            0,
            this.canvasHeight * ir,
            this.canvasHeight
          );
        } else if (br < ir) {
          this.ctx.drawImage(
            img,
            x,
            (this.canvasHeight - this.canvasWidth / ir) / 2,
            this.canvasWidth,
            this.canvasWidth / ir
          );
        } else {
          this.ctx.drawImage(img, x, 0, this.canvasWidth, this.canvasHeight);
        }
        //this.ctx.save()
      };
    },
    prepareImageList() {
      this.getImage().then((response) => {
        this.imageList[this.imageIndex] = response;
        this.drawImage(this.imageList[this.imageIndex]);
        for (let i = this.imageIndex + 1; i < 10; i++) {
          this.getImage().then((response) => {
            this.imageList[i] = response;
          });
        }
      });
    },
    slideHandle() {
      this.switchAnimate();
    },
    switchNextImg() {
      this.handleSwitchImg("next");
    },
    switchLastImg() {
      this.handleSwitchImg("last");
    },
    handleNextImage() {
      if (this.imageIndex + 1 === this.imageList.length) {
        this.showLoading = true;
        this.getRandomImage().then((data) => {
          this.showLoading = false;
          this.drawImage(
            "data:" +
              data.image.type +
              ";base64," +
              data.image.content.split("").reverse().join("")
          );
          this.imageItem = data;
          this.imageList.push(data.image.name);
          this.imageIndex++;
        });
      } else {
        this.showLoading = true;
        this.imageIndex++;
        this.getImageByName(this.imageList[this.imageIndex]).then((data) => {
          this.showLoading = false;
          this.drawImage(
            "data:" +
              data.image.type +
              ";base64," +
              data.image.content.split("").reverse().join("")
          );
          this.imageItem = data;
        });
      }
    },
    handlePreviousImage() {
      if (this.imageIndex === 0) {
        Notify({ type: "primary", message: "當前為第一頁" });
      } else {
        this.showLoading = true;
        this.imageIndex--;
        this.getImageByName(this.imageList[this.imageIndex]).then((data) => {
          this.showLoading = false;
          this.drawImage(
            "data:" +
              data.image.type +
              ";base64," +
              data.image.content.split("").reverse().join("")
          );
          this.imageItem = data;
        });
      }
    },
    handleSwitchImg(action) {
      console.log(action);
      if (action === "last") {
        if (this.imageIndex === 0) {
          Notify({ type: "primary", message: "当前为第一页" });
          console.log("first");
          return;
        } else {
          this.imageIndex--;
        }
      }

      if (action === "next") {
        if (this.imageIndex === 2) {
          this.imageList.shift();

          for (let i = 0; i < 10; i++) {
            if (!this.imageList[i]) {
              this.imageList[i] = null;
              this.getImage().then((response) => {
                this.imageList[i] = response;
              });
            }
          }
        } else if (this.imageIndex < 2) {
          this.imageIndex++;
        }
      }

      this.drawImage(this.imageList[this.imageIndex]);
    },
    getRandomImage() {
      return new Promise((resolve, reject) => {
        this.apiGetRequest("/image/random?t=" + Math.random()).then(
          (response) => {
            if (response.data.image.type === "redirect") {
              this.$axios.get(response.data.image.url).then((res) => {
                resolve({ image: res.data, meta: response.data.meta });
              });
            } else {
              resolve({
                image: response.data.image.content,
                meta: response.data.meta,
              });
            }
          }
        );
      });
    },
    getImageByName(name) {
      return new Promise((resolve, reject) => {
        this.apiGetRequest("/image/get?name=" + name).then((response) => {
          if (response.data.image.type === "redirect") {
            this.$axios.get(response.data.image.url).then((res) => {
              resolve({ image: res.data, meta: response.data.meta });
            });
          } else {
            resolve({
              image: response.data.image.content,
              meta: response.data.meta,
            });
          }
        });
      });
    },
    switchAnimate() {
      console.log("switch");
      let frame = this.ctx.getImageData(
        this.canvasWidth,
        0,
        200,
        this.canvasHeight
      );
      this.ctx.putImageData(frame, 0, 0);
      //this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      /*
      let x = 0;
      let animate = setInterval(() => {
        if (Math.abs(x) > this.canvasWidth) {
          clearInterval(animate);
        } else {
          x = x - this.canvasWidth / 60;
        }

        console.log(x);
        this.ctx.clearRect(
          0 - this.canvasWidth,
          0,
          2 * this.canvasWidth,
          this.canvasHeight
        );
        this.ctx.putImageData(frame, x, 0);
      }, 300 / 60);
      */
    },
    handleKeyDown(event) {
      switch (event.keyCode) {
        case 37:
          this.handlePreviousImage();
          break;
        case 39:
          this.handleNextImage();
          break;
      }
    },

    handleClick() {
      this.showControl = !this.showControl;
      this.$refs.container.style.backgroundColor = this.showControl
        ? "#FFFFFF"
        : "#000000";
      setThemeColor(this.showControl ? "#FFFFFF" : "#000000");
    },

    apiGetRequest(path) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(path, {
            headers: {
              token: getStorage("token"),
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              Notify({ type: "warning", message: "登錄狀態已過期" });
              removeStorage("token");
              this.$store.commit("userNotAuthed");
            }
            reject(err);
          });
      });
    },
    apiPostRequest(path, data) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(path, data, {
            headers: {
              token: getStorage("token"),
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              Notify({ type: "warning", message: "登錄狀態已過期" });
              removeStorage("token");
              this.$store.commit("userNotAuthed");
            }
            reject(err);
          });
      });
    },
    handleLikeClick() {
      this.apiPostRequest("/image/like", {
        name: this.imageItem.image.name,
      }).then((response) => {
        this.imageItem.meta.like = response.data.like;
      });
    },
    openRateBox() {
      this.selectedRate = 5;
      this.showRateBox = true;
    },
    updateRate() {
      this.apiPostRequest("/image/rate", {
        name: this.imageItem.image.name,
        rate: this.selectedRate,
      }).then((response) => {
        this.imageItem.meta.rate = response.data.rate;
      });
    },
  },
};
</script>

<style>
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000000;
}

#icp {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.cover {
  width: 100%;
  height: 100%;
  z-index: 999;
  color: #fff;
  font-size: 100px;
  position: absolute;
}

.loading {
  text-align: center;
  height: 70px;
  position: relative;
  top: 45%;
}

.lock {
  display: none;
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
}

.lockinfo {
  position: relative;
  top: 45%;
  font-size: 30px;
  color: #292421;
  text-align: center;
}

.control {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  color: #000000;
}

.info p {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}

.control {
  z-index: 1000;
}

.rate-box {
  height: 60px;
  padding: 20px;
}
</style>